const firebaseConfig = {
  apiKey: "AIzaSyCHIxsgN7ZcTVpKo4oCweCh03OfZq1b1J8",
  authDomain: "aichner-industries.firebaseapp.com",
  databaseURL: "https://aichner-industries.firebaseio.com",
  projectId: "aichner-industries",
  storageBucket: "aichner-industries.appspot.com",
  messagingSenderId: "865119026393",
  appId: "1:865119026393:web:e92703873ae554ddd8d2c8",
};

export default firebaseConfig;

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2020 Christian Aichner
 */
